import React from 'react';
import { useFormikContext } from 'formik';

export default function SubmitBtn({ children }) {
  const { isValid, isSubmitting, dirty } = useFormikContext();
  return (
    <div>
      <button
        className="pure-button pure-button-primary"
        type="submit"
        disabled={!dirty || !isValid || isSubmitting || null}
      >
        {children}
      </button>
    </div>
  );
}
