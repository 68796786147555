import axios from 'axios';
import { prop } from 'ramda';

const getData = prop('data');

function createApi({ baseURL }) {
  function getToken({ q, docs }) {
    return axios.post(`${baseURL}/partners/${q}`, { docs }).then(getData);
  }

  function upload({ q, files }) {
    return getToken({ q, docs: files.map(toDoc) }).then(sendFiles(files));
  }

  function sessionUpload(token, files) {
    return axios
      .post(`${baseURL}/sessions/${token}`, { docs: files.map(toDoc) })
      .then(getData)
      .then(sendFiles(files));
  }

  return { upload, sessionUpload };

  function sendFiles(files) {
    return ({ tokens }) =>
      Promise.all(
        tokens.map(function (token, id) {
          const file = files[id];
          return axios.put(token, file);
        })
      );
  }

  function toDoc(file) {
    return { name: file.name };
  }
}
export default createApi({ baseURL: process.env.REACT_APP_API_URL });
