import { useField } from 'formik';
import PropTypes from 'prop-types';

export default function FileField(props) {
  const [field, meta, helpers] = useField(props.name);

  function handleChange(event) {
    helpers.setValue(Array.from(event.target.files));
  }

  return (
    <input
      type="file"
      {...props}
      onChange={handleChange}
      onBlur={field.onBlur}
    />
  );
}
FileField.propTypes = { name: PropTypes.string.isRequired };
