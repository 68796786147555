import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  useField,
} from 'formik';
import api from './api';
import SubmitBtn from './form/SubmitBtn';
import { useState } from 'react';
import * as y from 'yup';
import { identity } from 'ramda';
import FileField from './FileField';

const qNumberLength = 5;

const schema = y.object({
  isToken: y.boolean(),
  // q: y.string().required('Q number is required'),
  q: y.string().when('isToken', {
    is: false,
    then: (t) =>
      t
        .required('Q number is required')
        .matches(
          new RegExp(`\\d{${qNumberLength}}`),
          `Q number is a ${qNumberLength} digits number`
        ),
    otherwise: identity,
  }),
  files: y
    .array()
    .of(y.array().min(1, 'Required'))
    .min(1, 'Add at least 1 document'),
});

function App() {
  const params = new URLSearchParams(document.location.search);
  const token = params.get('t');
  const [flashMsg, setFlashMsg] = useState(null);

  function handleSubmit({ q, files }) {
    const flatFiles = files.flat();
    if (token) {
      return api.sessionUpload(token, flatFiles).then(success, fail);
    }
    return api.upload({ q, files: flatFiles }).then(success, fail);

    function success() {
      setFlashMsg(() => (
        <>
          <h3>Success. Your files have been sent successfully.</h3>
          <p>
            Thank you for using <a href="https://meovak.com">Meovak</a>.
          </p>
        </>
      ));
    }

    function fail(err) {
      setFlashMsg(() => (
        <h4 className="error">
          An error occurred: {err.message}.<br />
          Please try again.
        </h4>
      ));
      setTimeout(function () {
        setFlashMsg(null);
      }, 5000);
    }
  }

  if (flashMsg) {
    return <div className="container">{flashMsg}</div>;
  }
  return (
    <div className="container pure-g">
      <h1>Supporting documents</h1>
      <Formik
        initialValues={{ isToken: token !== null, q: '', files: [[]] }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form className="pure-form pure-form-stacked pure-u-1-1">
          {!token && <QNumberField />}
          <FieldArray name="files" component={Files} />
          {/*<FormError />*/}
          <fieldset>
            <SubmitBtn>Send</SubmitBtn>
          </fieldset>
        </Form>
      </Formik>
    </div>
  );
}
// function FormError() {
//   const { errors } = useFormikContext();
//   return <div className="error">{pipe(Object.values, head)(errors)}</div>;
// }

function QNumberField() {
  return (
    <fieldset>
      <label htmlFor="q">Your Q Number</label>
      <Field name="q" type="text" inputMode="numeric" placeholder="Q number" />
      <ErrorMessage className="error" name="q" component="div" />
    </fieldset>
  );
}

function Files(h) {
  const [field, meta, helper] = useField('files');
  return (
    <>
      <fieldset>
        {field.value.map(([file], index) => {
          const name = `files.${index}`;

          return (
            <div key={index}>
              <label htmlFor={name}>Document {index + 1}</label>
              <FileField
                name={name}
                id={name}
                accept=".jpg, .pdf, .png, .jpeg"
              />
              <ErrorMessage className="error" name={name} component="div" />
              <button
                className="pure-button btn-xs"
                type="button"
                onClick={() => h.remove(index)}
              >
                Remove
              </button>
            </div>
          );
        })}
      </fieldset>
      <fieldset>
        <button
          className="pure-button btn-secondary btn-sm"
          type="button"
          onClick={() => h.push([])}
        >
          Add document
        </button>
        <p className="error">{meta.error}</p>
      </fieldset>
    </>
  );
}

export default App;
